import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 导入按需导入的配置文件
import '@/utils/vant-ui'

// 导入重置默认样式
import '@/styles/common.less'

// 全部导入
// import Vant from 'vant'
// import 'vant/lib/index.css'

// Vue.use(Vant)

// 按需导入
// import { Button, Switch } from 'vant'

// Vue.use(Button)
// Vue.use(Switch)

Vue.config.productionTip = true

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
