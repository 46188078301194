<template>
  <div class="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data () {
    return {
      checked: true,
      scored: 100
    }
  }
}
</script>

<style lang="less">
.box {
  width: 300px;
  height: 300px;
  background-color: pink;
}
</style>
