import Vue from 'vue'
import VueRouter from 'vue-router'

import Layout from '@/views/layout'
import Home from '@/views/layout/home'
import Cart from '@/views/layout/cart'
import Category from '@/views/layout/category'
import User from '@/views/layout/user'

import store from '@/store/index'

const Login = () => import('@/views/login')
const Search = () => import('@/views/search')
const SearchList = () => import('@/views/search/list')
const ProDetail = () => import('@/views/prodetail')
const Pay = () => import('@/views/pay')
const MyOrder = () => import('@/views/myorder')

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    { path: '/', redirect: '/home' },
    { path: '/login', component: Login },
    {
      path: '/',
      component: Layout,
      children: [
        { path: '/home', component: Home },
        { path: '/category', component: Category },
        { path: '/cart', component: Cart },
        { path: '/user', component: User }
      ]
    },
    { path: '/search', component: Search },
    { path: '/searchlist', component: SearchList },
    // 动态路由传参, 确认将来是哪个商品, 路由参数中携带 id
    { path: '/prodetail/:id', component: ProDetail },
    { path: '/pay', component: Pay },
    { path: '/myorder/', component: MyOrder }
  ]
})

// 所有的路由在真正被访问到之前(解析渲染对应组件页面前), 都会先经过全局前置守卫
// 只有全局前置守卫放行, 才会到达对应的页面

// 全局前置导航守卫
// to: 到哪里去. 到哪去的完整路由信息对象 (路径, 参数)
// from: 从哪里来, 从哪来的完整路由信息对象 (路径, 参数)
// next(): 是否放行
// 1. next() 直接放行, 放行到 to 要去的路径
// 2. next(路径) 进行拦截, 拦截到 next 里面配置的路径

// 定义权限数组(专用于存放所有需要权限访问的页面)
const authUrls = ['/pay', '/myorder']

router.beforeEach((to, from, next) => {
  if (!authUrls.includes(to.path)) {
    // 非权限组件, 直接放行
    next()
    return
  }

  // 是权限页面, 需要判断 token
  const token = store.getters.token
  if (token) {
    next()
  } else {
    next('/login')
  }
})

export default router
