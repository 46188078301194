<template>
    <div class="count-box">
        <button class="minus" @click="handleSub">-</button>
        <input class="inhere" @change="handleChange" :value="value" type="text">
        <button class="add" @click="handleAdd">+</button>
    </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 1
    }
  },
  methods: {
    handleSub () {
      if (this.value <= 1) {
        return
      }

      this.$emit('input', this.value - 1)
    },

    handleAdd () {
      this.$emit('input', this.value + 1)
    },

    handleChange (e) {
      const num = +e.target.value
      if (isNaN(num) || num < 1 || (String(num).indexOf('.') + 1)) {
        e.target.value = this.value
        return
      }

      this.$emit('input', num)
    }
  }
}
</script>

<style lang="less" scoped>
    .count-box {
        display: flex;
        width: 110px;
        .add, .minus {
            width: 30px;
            height: 30px;
            outline: none;
            border: none;
            background-color: #efefef;
        }

        .inhere {
            width: 40px;
            height: 30px;
            outline: none;
            border: none;
            margin: 0 5px;
            text-align: center;
            background-color: #efefef;
        }
    }
</style>
